import '../../shared/filters_sidebar/filters_sidebar'

import { currentDevice } from '../../core/utils/currentDevice'
import { initFilterOrderPanel } from '../../shared/filter_order_panel/filter_order_panel'

let handleRootDestinationClick
let handleClosePanelClick
let handleFilterDestListLinkClick

/* eslint-disable */
export function init() {
  // Feature/73371: se ha dejado mucha lógica antigua ya que se usa para los filtros del modal de gastronomía y del information-module

  if (currentDevice.isMobile && !document.body.classList.contains('inspiration-guide')) {
    initFilterOrderPanel();
    $('[data-mobile-panel]').mobilepanel();
  }

  if (!$('[data-root-destination]').length) return false;

  let $root_destinations = $('.root-destination');

  handleRootDestinationClick = function() {
    selectDestination($(this))
  }

  $('[data-root-destination]').on('click', handleRootDestinationClick);

  function selectDestination (elem) {
    let $el = elem;
    let $target = $('.' + $el.attr('data-root-destination'));

    $('[data-root-destination]').removeClass('active');
    $el.addClass('active');

    if (!currentDevice.isMobile) {
      if ($('#hotels-filter-map').is(':visible')) {
        $root_destinations.not($target).removeClass('current');
        $target.addClass('current');
        IB.hotels_filter_map.filterVisible();
        $('.js-filters-sidebar').find('.root-destination').not($target)
          .addClass('hidden');
        $('.js-filters-sidebar').find($target).removeClass('hidden');
      } else {
        $root_destinations.not($target).addClass('hidden');
        $target.removeClass('hidden');
      }
    } else {
      $root_destinations.not($target).addClass('hidden');
      $target.removeClass('hidden');
    }
    if($('[data-filterable-wrap]').length)
      window.FilterSidebar.clear($('[data-filterable-wrap]'));

    elem.get(0).dispatchEvent(new CustomEvent('root-destination:tab-changed'))
  }

  if (!currentDevice.isMobile) return false;

  $('[data-root-destination]').each(function (index, el) {
    let $el = $(el).clone();
    let data_root = $el.attr('data-root-destination');
    $el.find('.hotel-count').remove();

  });

  let label = $('.filter-dest-list-link').data('mobile-panel-title');
  let $filters_sidebar = $('.js-filters-sidebar');
  let $filters_panel_title = $('<p class="panel-title">' + label + '</p>');
  let $panel = $('<div id="mobile-filter-panel" class="mobile-panel filters-panel" data-mobile-panel data-inline></div>');
  let $destination_filter = ($('.destination-index-tabs-nav').length) ? '.destination-index-tabs-nav' : '.destination-select select';
  let $destination_filter_selection = ($('.destination-index-tabs-nav').length) ? 'li.active' : 'option:selected';
  let $default_value = $($destination_filter).find($destination_filter_selection).attr('data-root-destination');
  let $list = $('<div>').attr('id', 'filter-dest').addClass('formz filter-dest-panel')
    .attr('data-current-selection', $default_value)
    .attr('data-panel-option-list', '')
    .attr('data-trigger-click', '');

  $($destination_filter).find('[data-root-destination]').each(function (index, el) {
    let $radio_button = '<input type="radio" name="[filter_order]" id="' + $(el).data('root-destination') + '" value="' + $(el).data('root-destination') + '">';
    let final_text = $(el).html();
    let $radio_button_label = '<label class="t-p label-col" for="' + $(el).data('root-destination') + '">' + final_text + '</label>';
    if ($('#filter-dest').length <= 0) $list.append($radio_button + $radio_button_label + '<br>');
  });

  let $filter_dest_panel_title = $('<p class="panel-title">' + label + '</p>');
  let $apply_text = $('.filter-dest-list-link').data('mobile-panel-apply');
  let $filter_dest_panel_button = $('<a class="secondary-btn mobile-only" data-close-panel="" href="#">' + $apply_text + '</a>');
  let $panel_filter_dest = $('<div id="mobile-filter-panel-dest" class="mobile-panel filters-panel mobile-filter-panel-dest" data-mobile-panel data-inline></div>');

  $('body').append($panel, $panel_filter_dest);
  $('#mobile-filter-panel').append($filters_panel_title, $filters_sidebar);
  $('#mobile-filter-panel-dest').append($filter_dest_panel_title, $list, $filter_dest_panel_button);

  $(document).on('click', '[data-close-panel]', handleClosePanelClick);

  handleFilterDestListLinkClick = function () {
    let $current_selected = $('[data-panel-option-list]').attr('data-current-selection');
    $('#filter-dest input[value="' + $current_selected + '"]').click();
  }
  $(document).on('click', '.filter-dest-list-link', handleFilterDestListLinkClick);
}

init();

// Collapsables of new destination page
function collapsableHandler() {
  const collapsableBlock = document.querySelectorAll('.js-destination-header-collapsable')

  collapsableBlock.forEach(element => {
    const collapseButton = element.querySelector('.js-destination-header-collapsable-button')
    element.addEventListener('click', () => {
      collapseButton.classList.toggle('active')
    })
  })
}

collapsableHandler()
